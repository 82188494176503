/* WindowsPage.css */

.windowsContainer {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    /* Customize styles for the windows container */
  }
  
  .windowsContent {
    display: flex;
    background-color: #d3e3fd;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    /* Customize styles for the content section */
  }
  
  .windowsImage {
    width: 45%;
    max-width: 450px;
    height: 450px;
    border-radius: 8px;
    margin-top: 5px;
    margin-left: 5px;
    /* Customize styles for the image */
  }
  
  .windowsDescription {
    width: 50%;
    margin-left: 20px;
    /* Customize styles for the description section */
  }
  
  .windowsDescription p {
    margin-bottom: 15px;
    font-size: 18px;
    line-height: 1.6;
    /* Customize styles for the paragraphs within the description */
  }
  
  /* For smaller devices */
  @media (max-width: 768px) {
    .windowsContent {
      flex-direction: column;
      text-align: center;
    }
  
    .windowsImage {
      width: 100%;
      margin-bottom: 20px;
    }
  
    .windowsDescription {
      width: 100%;
      margin-left: 0;
    }
  }
  