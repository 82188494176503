/* aboutGallery.css */

.gallery1Container {
    margin: 20px auto; /* Center the container horizontally */
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    max-width: 700px; /* Set a maximum width */
    width: 90%; /* Take 90% width of the viewport */
    height: 700px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .carousel1Container {
    position: relative;
    margin-top: 20px;
    width: 400px;
    height: 700px;
   
  }
  
  .carousel1Containerimg img {
    width: 300px;
    height: 500px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  }
  
  .image1-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  }
  
  .image1-actions button {
    padding: 10px 20px;
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .image1-actions button:hover {
    background-color: #555;
  }
  
  .galleryIcon {
    margin: 0 5px;
    cursor: pointer;
    font-size: 24px;
    transition: transform 0.3s ease-in-out;
  }
  
  .galleryIcon:hover {
    transform: scale(1.2); /* Increases the size of the icon on hover */
  }
  .galleryIcon2 {
    margin: 0 5px;
    cursor: pointer;
    font-size: 24px;
    transition: transform 0.3s ease-in-out;
  }
  .galleryIcon2:hover {
    transform: scale(1.2); /* Increases the size of the icon on hover */
  }

  /* Media Query for small devices */
@media (max-width: 768px) {
    .gallery1Container {
      margin: 10px auto; /* Adjust margin for smaller screens */
      padding: 10px;
      height: auto; /* Let the height adjust based on content */
    }
    .carousel1Container {
        width: 100%;
      }
      .galleryIcon {
        font-size: 24px;

      }
      .galleryIcon2{
        font-size: 14px;
      }
  }
  
  /* Media Query for medium devices */
  @media (max-width: 992px) {
    .gallery1Container {
      max-width: 90%; /* Take 90% width of the viewport */
    }
  }


  