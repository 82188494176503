/* EnquireNowSection.css */

.enquireCard {
    background-color: #0000fe;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 40px;
    text-align: center;
    max-width: 600px;
    margin: 0 auto;
    margin-bottom: 10px;
  }
  
  .enquireCard h2 {
    font-size: 28px;
    color: #fff;
    margin-bottom: 20px;
  }
  
  .enquireCard p {
    font-size: 16px;
    color: #ffffff;
    margin-bottom: 30px;
  }
  
  .enquireForm {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .enquireForm input[type="text"] {
    width: 400px;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .enquireFormButton {
    padding: 12px 24px;
    background-color: #ffffff;
    color: #000;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .enquireFormButton:hover {
    background-color: #0013a1;
    color: #fff;
  }
  
  /* Media Queries */
  
  @media (max-width: 768px) {
    .enquireCard {
      width: 350px;
      padding: 20px;
    }
    .enquireForm input[type="text"] {
      width: 250px;
    }
    .enquireCard h2 {
      font-size: 24px;
    }
  
    .enquire-card p {
      font-size: 14px;
    }
  }
  
  @media (max-width: 480px) {
    .enquireCard {
      padding: 10px;
    }
  
    .enquireCard h2 {
      font-size: 20px;
    }
  
    .enquireCard p {
      font-size: 12px;
    }
  
    .enquireFormButton {
      font-size: 14px;
      padding: 10px 20px;
    }
  }
  