/* AboutPage.css */

.about-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .about-content {
    display: flex;
    background-color: #0000fe;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    color: #ffffff;
  }
  
  .about-image {
    width: 45%;
    max-width: 400px;
    height: 450px;
    border-radius: 8px;
  }
  
  .about-description {
    width: 50%;
    margin-left: 20px;
  }
  
  .about-description p {
    margin-bottom: 15px;
    font-size: 18px;
    line-height: 1.6;
  }
  
  /* For smaller devices */
  @media (max-width: 768px) {
    .about-content {
      flex-direction: column;
      text-align: center;
    }
  
    .about-image {
      width: 100%;
      margin-bottom: 20px;
    }
  
    .about-description {
      width: 100%;
      margin-left: 0;
    }
  }
  