/* AboutUs.css */

.aboutSection {
    background-color: #f9f9f9;
    padding: 40px 0;
    text-align: center;
    width: 100%;
  }
  
  .aboutCard {
    max-width: 1200px;
    margin: 0 auto;
    background-color: #fff;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    display: flex;
    flex-direction: row-reverse; /* Display text before image */
  }
  
  .aboutText {
    flex: 1;
    padding: 20px;
    text-align: left;
  }
  
  .aboutText h2 {
    font-size: 32px;
    color: #0000fe;
    margin-bottom: 10px;
  }
  
  .aboutText p {
    font-size: 16px;
    color: #666;
    line-height: 1.5;
    margin-bottom: 20px;
  }
  
  .read-moreButton {
    display: inline-block;
    padding: 10px 20px;
    background-color: #0000fe;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    font-weight: bold;
    transition: background-color 0.3s;
  }
  
  .read-moreButton:hover {
    background-color: #0013a1;
  }
  
  .about-usImage {
    flex: 1;
    text-align: center;
  }
  
  .about-usImage img {
    max-width: 100%;
    height: auto;
  }
  
  /* Media Queries */
  @media (max-width: 767px) {
    .aboutCard {
      flex-direction: column;
    }
  
    .aboutText {
      text-align: left;
    }
  
    .aboutText h2 {
      font-size: 20px;
    }
  
    .aboutText p {
      font-size: 16px;
    }
  
    .about-usImage {
      text-align: center;
      margin-top: 20px;
    }
  }
  