/* NavigationBar.css */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #0000fe;
    padding: 20px;
    color: #fff;
    height: 60px;
  }
  
  .logo {
    /* Your logo styles */
  }
  
  .logo-img {
    max-width: 250px; /* Adjust the max-width of the logo */
    margin-top: 10px;
  }
  
  .menu {
    list-style: none;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
  }
  
  .menu-item {
    margin: 0 15px;
    position: relative;
    margin-right: 50px;
  }
  
  .menu-link {
    text-decoration: none;
    color: #fff;
    margin-top: 10px;
    /* position: relative; */
  }
  
  .menu-item.has-submenu::after {
    content: '';
    position: absolute;
    top: 50%;
    right: -10px;
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 6px solid #fff; /* Arrow styling */
    transform: translateY(-20%) rotate(90deg); /* Initially facing downwards */
    transition: all 0.3s ease;
  }
  
  .menu-item.has-submenu:hover::after {
    transform: translateY(-50%) rotate(-90deg); /* Rotate arrow on hover */
  }
  
  .submenu {
    list-style: none;
    padding: 10px;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #0000fe;
    display: none;
    z-index: 1;
    width: 80px;
  }
  
  .menu-item:hover .submenu {
    display: block;
  }
  .menu-link:hover {
    color: #ff0000; 
  }
  
  /* Submenu item hover */
  .submenu-link:hover {
    color: #00ff00; 
  }
  
  .submenu-item {
    margin: 5px 0;
  }
  
  
  .submenu-link {
    text-decoration: none;
    color: #fff;
  }
  
  .close-icon {
    display: none;
    color: #fff;
    font-size: 24px;
    cursor: pointer;
  }

.hamburger {
    display: none; /* Initially hidden on larger screens */
    cursor: pointer;
    padding: 10px;
  }
  
  .hamburger .line {
    width: 30px;
    height: 3px;
    background-color: #fff;
    margin: 5px 0;
    transition: transform 0.3s ease-in-out;
  }
  
  .menu {
    list-style: none;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    transition: transform 0.3s ease-in-out;
    transform: translateY(-100%);
  }
  
  .menu.open {
    /* transform: translateY(0); */
    border-top: 3px solid #d08f29; /* Set your desired color and width */
    transform: translateY(calc(90px + 3px)); /* Account for line and menu height */
  }
  
  
  /* Media Query for smaller screens */
  @media (max-width: 768px) {
    .hamburger {
      display: block; /* Show hamburger on smaller screens */
    }
  
    .menu {
      position: fixed;
      top: 0;
      left: 20%; /* Center the menu */
      width: 70%;
      height: 40vh;
      flex-direction: column;
      background-color: #0000fe;
      padding-top: 60px; /* Adjust for logo height */
      z-index: 1;
      font-size: 18px;
    }

    .close-icon {
        display: block;
        color: red;
        font-size: 18px;
        cursor: pointer;
        top: 20px; 
        right: 20px; 
        z-index: 2
      }
      /* Hide the hamburger lines when the menu is open */
   .menu.open .hamburger .line {
    display: none;
  }

  }
  