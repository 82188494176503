/* WhyUsSection.css */

.whySection {
    background-color: #f5f5f5; /* Adjust as needed */
    padding: 40px 0;
    text-align: center;
    width: 100%;
  }
  
  .whyCard {
    max-width: 1200px;
    margin: 0 auto;
    background-color: #fff; /* Adjust as needed */
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    display: flex;
    flex-direction: row; /* Display both cards in a row */
    margin-bottom: 20px; /* Gap between cards */
  }
  
  .whyText,
  .whyText1 {
    flex: 1;
    padding: 20px;
    text-align: left;
  }
  
  .whyText h2,
  .whyText1 h2 {
    font-size: 24px; /* Adjust font size */
    color: #000; /* Adjust color */
    margin-bottom: 10px;
  }
  .CountP {
    font-weight: bold !important;
    font-size: 24px !important;
    color: #0000fe !important;
  }
  
  .whyText p {
    font-size: 16px; /* Adjust font size */
    color: #666; /* Adjust color */
    line-height: 1.5;
    margin-bottom: 20px;
  }
  
  /* Media Queries */
  @media (max-width: 767px) {
    .whyCard {
      flex-direction: column; /* Stack cards in a column */
    }
  }
  