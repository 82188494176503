/* Hero.css */

.heroContainer {
    position: relative;
    width: 100%;
    height: 80vh;
    overflow: hidden;
  }
  
  .videoBackground {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -1;
  }
  
  .heroContent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
    color: #fff;
  }
  
  .heroText {
    margin-left: 10px;
    color: #fb010a;
    font-size: 28px;
    font-weight: bold;
    z-index: -1;
  }

  
.solutionText {
    color: #ffffff; 
    margin-left: 10px;
    z-index: -1;
  }
  
  .heroText h1 {
    font-size: 3em;
    margin: 0;
  }
  
  .quoteButton1 {
    padding: 15px 30px;
    font-size: 18px;
    background-color: #0000fe;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-left: 70%;
    margin-top: 30vh;
  }
  
  .quoteButton1:hover {
    background-color: #0013a1;
  }
  
  /* Media Query for smaller screens */
  @media (max-width: 768px) {
    .heroContainer {
      height: 80vh;
    }
  
    .heroText {
      margin-left: 0;
      margin-top: 20vh;
      font-size: 18px;
    
    }
  
    .quoteButton1 {
      margin-left: 10px;
      margin-top: 10vh;
      font-size: 14px;
    }
  }
  