/* ServicesSection.css */

.services-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    padding: 20px;
    gap: 20px; /* Gap between the cards */
    background-color: #0000fe; 
    padding: 20px;
    width: 100%;
    box-sizing: border-box; /* Include padding in width calculation */
  }
  
  .flip-card {
    background-color: transparent;
    width: 350px;
    height: 400px;
    perspective: 1000px; /* Adding perspective for 3D effect */
    box-sizing: border-box; /* Include margin in width calculation */
    margin-bottom: 30px; /* Bottom margin for spacing between rows */
  }

  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }
  
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
  }
  
  .flip-card-front {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: #f2f2f2;
  }
  
  .flip-card-back {
    background-color: #f9f9f9;
    transform: rotateY(180deg);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  
  .flip-card img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 10px;
  }
  
  .flip-card h3 {
    margin-top: 20px;
    color: #333;
  }
  
  .flip-card p {
    color: #666;
    font-size: 14px;
    margin-bottom: 20px;
  }
  
  .view-button {
    padding: 10px 20px;
    background-color: #0000fe;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    font-weight: bold;
    transition: background-color 0.3s;
  }
  
  .view-button:hover {
    background-color: #0013a1;
  }
  

  /* Media Query for medium devices */
@media (max-width: 992px) {
    .flip-card {
      width: calc(46% - 20px); /* Two cards in a row on medium devices */
    }
  }
  
  /* Media Query for small devices */
  @media (max-width: 600px) {

    .services-section {
      padding: 10px; /* Adjust padding for better alignment on small devices */
    }
    .flip-card {
      width: calc(88% - 20px); /* One card per row on small devices */
      /* margin-left: 10px; */
      /* margin-right: 10px;  */
    }
  }
