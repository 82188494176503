/* CallTodayButton.css */

/* Styling for the Call Today button */
.call-today {
    display: none; /* Initially hide the button */
    position: fixed;
    bottom: 40px;
    right: 20px;
    z-index: 1000;
  }
  
  .call-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #007bff;
    color: white;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    text-decoration: none;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .call-button > span {
    display: none; /* Use a more specific selector */
  }
  .callText{
    color:#777;
  }
  
  .call-button::before {
    content: "\1F4DE"; /* Unicode for phone icon */
    font-size: 24px;
  }
  
  /* Media query to show the button only on small devices and tablets */
  @media (max-width: 992px) {
    .call-today {
      display: block; /* Show the button on smaller screens */
    }
  
    .call-button > span {
      display: inline-block; /* Adjust display for smaller screens */
    }
  }
  