/* Global Styles */


body {
    font-family: Arial, sans-serif;
    background-color: #f2f5fa;
    overflow-x: hidden; /* Hide horizontal scrollbar */
    width: 100%;
    
  }

body, html, .container {
  margin: 0;
  padding: 0;
}

.App {
  /* width: 100%;
  padding: 1rem; */
  overflow-x: hidden;
} 



  
  /* Add more global styles as needed */
  