/* ContactSection.css */

/* Styles for the main contact section */
.form1contactSection {
    padding: 20px; 
  }
  .form1H2 {
    font-size:32px;
    color: #007bff;
  }
  
  /* Grid container */
  .grid-Form1container {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two columns for larger screens */
    gap: 20px; /* Adjust the gap between columns */
  
    /* Media query for smaller devices */
    @media (max-width: 768px) {
      grid-template-columns: 1fr; /* Single column for smaller screens */
    }
  }
  
  /* Styles for the contact information section */
  .contact-info {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 5px;
  }
  
  .contact-info h2 {
    margin-top: 0;
    font-size: 18px;
  }
  
  .contact-details {
    margin-top: 20px;
  }
  
  .contact-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  .Form1Icon{
    margin-right: 5px;
  }
  
  /* Styles for the WhatsApp icon */
  .whatsapp1icon h2 {
    font-size: 18px;
  }
  .whatsapp1icon img {
    width: 40px; /* Adjust the width of the WhatsApp icon */
    height: auto; /* Maintain aspect ratio */
    cursor: pointer;
  }
  
  /* Styles for the contact form section */
  .contactForm1 {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 5px;
  }
  
  .contactForm1 h2 {
    margin-top: 0;
    font-size: 18px;
  }
  
  .form1-input-group {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .form1-input-group input,
  .form1-input-group textarea {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  .form1-input-group textarea {
    height: 150px;
  }
  
  .error-message {
    color: red;
    font-size: 12px;
    margin-top: 5px;
  }
  
  .form1Button[type='submit'] {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .form1Button[type='submit']:hover {
    background-color: #0056b3;
  }
  
  .success-message {
    color: green;
    font-weight: bold;
  }
  

  