/* GatesPage.css */

.gatesContainer {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .gatesContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    background-color: #d3e3fd;
  }
  
  .gatesImage {
    width: 45%;
    max-width: 400px;
    height: 450px;
    border-radius: 8px;
  }
  
  .gatesDescription {
    width: 50%;
    margin-left: 20px;
  }
  
  .gatesDescription p {
    margin-bottom: 15px;
    font-size: 18px;
    line-height: 1.6;
  }
  .gateCallofAction{
    color: #0000fe;;

  }
  .gateQuoteButton {
    background-color: #0000fe;
    padding-left: 50px;
  }
  .gateQuoteButton:hover {
    color: #00ff00; 
  }
  /* For smaller devices */
  @media (max-width: 768px) {
    .gatesContent {
      flex-direction: column;
      text-align: center;
    }
  
    .gatesImage {
      width: 100%;
      margin-bottom: 20px;
    }
  
    .gatesDescription {
      width: 100%;
      margin-left: 0;
    }
  }
  