/* DoorsPage.css */

.doorsContainer {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .doorsContent {
    display: flex;
    background-color: #ccc;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
  }
  
  .doorsImage {
    width: 45%;
    max-width: 400px;
    height: 450px;
    border-radius: 8px;
  }
  
  .doorsDescription {
    width: 50%;
    margin-left: 20px;
  }
  
  .doorsDescription p {
    margin-bottom: 15px;
    font-size: 18px;
    line-height: 1.6;
  }

  .quoteButton {
    background-color: #0000fe; /* Green background color */
    color: white; /* White text color */
    padding: 10px 10px; 
    font-size: 16px; /* Font size */
    border: none; /* Remove border */
    border-radius: 4px; /* Rounded corners */
    cursor: pointer; /* Add cursor pointer on hover */
  }
  
  /* Hover effect */
  .quoteButton:hover {
    background-color: #0013a1; /* Darker green on hover */
  }
  
  /* For smaller devices */
  @media (max-width: 768px) {
    .doorsContent {
      flex-direction: column;
      text-align: center;
    }
  
    .doorsImage {
      width: 100%;
      margin-bottom: 20px;
    }
  
    .doorsDescription {
      width: 100%;
      margin-left: 0;
    }
  }
  