/* ScrollToTopButton.css */

.scroll-to-top1Button {
    position: fixed;
    bottom: 0px;
    right: 10px;
    /* background-color: #007bff; */
    color: #007bff;;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    font-size: 24px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.3s;
    opacity: 0;
  }
  
  .scroll-to-top1Button.visible {
    opacity: 1;
  }
  
  .scroll-to-top1Button .scrollTopIcon {
    color: #007bff;
  }
  