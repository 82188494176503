/* Footer.css */

/* General Footer Styles */
.footer {
    background-color: #0013a1;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
  }
  
  .footer-column {
    flex: 1 1 30%;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
  }
  
  /* Logo Styles */
  .footer-logo {
    margin-bottom: 0;
    height: 80px;
    margin-top: 0;
    /* Your logo styles */
  }
  
  /* Caption Styles */
  .footer-caption {
    color: #666;
    font-size: 14px;
    text-align: center;
    margin-top: 90px; /* Adjust margin to control distance from logo */
  }
  
  /* Services Styles */
  .services-list {
    list-style: none;
    padding: 0;
  }
  
  .services-list li {
    margin-bottom: 5px;
  }
  .services-list li a {
    text-decoration: none; /* Remove underline */
    color: #fff; /* Change link color */
    transition: color 0.3s ease; 
  }
  
  /* Change link color on hover */
  .services-list li a:hover {
    color: #ccc; 
  }
  
  /* Location Styles */
  .footer-location {
    text-align: center;
    color: #ccc;
  }
  
  .social-icons {
    gap: 10px; 
    /* Style your social media icons */
  }
  
  /* Horizontal Line */
  .footer-line {
    width: 100%; /* Ensures the line spans the entire width */
    border: none;
    border-top: 1px solid #ccc;
    margin: 20px 0;
  }
  
  /* Copyright */
  .footer-copyright {
    /* text-align: center !important; */
    color: #666;
    font-size: 14px;
    margin-right: 10px !important;
  }
  
  /* Media Queries for Responsive Design */
  @media (max-width: 768px) {
    .footer-column {
      flex: 1 1 100%;
    }
  }
  