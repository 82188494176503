/* StairsPage.css */

.stairsContainer {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    /* Add styles specific to the stairs container */
  }
  
  .stairsContent {
    display: flex;
    background-color: #ccc;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    /* Add styles for the content section */
  }
  
  .grillImage {
    width: 45%;
    max-width: 400px;
    height: 450px;
    border-radius: 8px;
    /* Add styles for the image */
  }
  
  .StairsDescription {
    width: 50%;
    margin-left: 20px;
    /* Add styles for the description section */
  }
  
  .StairsDescription p {
    margin-bottom: 15px;
    font-size: 18px;
    line-height: 1.6;
    /* Add styles for the paragraphs within the description */
  }
  
  /* For smaller devices */
  @media (max-width: 768px) {
    .stairsContent {
      flex-direction: column;
      text-align: center;
    }
  
    .grillImage {
      width: 100%;
      margin-bottom: 20px;
    }
  
    .StairsDescription {
      width: 100%;
      margin-left: 0;
    }
  }
  